import { Button, Spinner } from 'react-bootstrap';

export function LoadingButton(props: {
  type: string;
  text: string;
  imagePath: string | null;
  loading: boolean;
}) {
  return (
    <Button type={'submit'} className={`${props.type}-button m-auto mb-3 mt-2`} disabled={props.loading}>
      {props.loading ? (
        <span className={'d-flex justify-content-center'}>
          <Spinner size={'sm'} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <span className={'ms-2'}>{props.text}</span>
        </span>
      ) : (
        <p>
          {props.imagePath !== null ? (
            <img src={props.imagePath} width="25" height="25" alt={'img'} />
          ) : (
            <></>
          )}
          <span className={'ms-1'}>{props.text}</span>
        </p>
      )}
    </Button>
  );
}
