import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import {
  displayInPlaceButton,
  displayReadyToLeaveButton,
  enableAbsentButton,
  enableDropOffButton,
  enablePickUpButton,
  getBookingsToDropOff,
  getBookingsToPickUp,
  isDropOffButtonChecked,
  isPickUpButtonChecked,
} from './utils.ts';
import { Booking } from '../../../interfaces/Booking.ts';
import { postActions } from '../../../queries/postActions.ts';
import {
  ACTION_NAME_ABSENT,
  ACTION_NAME_DROP_OFF,
  ACTION_NAME_IN_PLACE,
  ACTION_NAME_PICK_UP,
  ACTION_NAME_READY_TO_LEAVE,
} from '../../../constants.ts';
import { toast } from 'react-toastify';
import { useContext, useState } from 'react';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { useTranslation } from 'react-i18next';
import { Place } from '../../../interfaces/Place.ts';
import { FormatTime } from '../../../utils.ts';
import { Territory } from '../../../interfaces/Territory.ts';
import { IShift } from '../../../interfaces/IShift.ts';

interface Props {
  currentPlace: undefined | Place;
  selectedPlace: Place | null;
  nextPlace: undefined | Place;
  territorySlug: string;
  showPlaceDetails: boolean;
  handleClosePlaceDetails: () => void;
  territoryObj: Territory | undefined;
  shift: IShift | undefined;
}
export function RoadmapActions(props: Props) {
  const { t } = useTranslation();
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const [isInPlaceButtonLoading, setIsInPlaceButtonLoading] = useState<boolean>(false);
  const [isReadyToLeaveButtonLoading, setIsReadyToLeaveButtonLoading] = useState<boolean>(false);
  const [isPickUpButtonLoading, setIsPickUpButtonLoading] = useState<boolean>(false);
  const [isDropOffButtonLoading, setIsDropOffButtonLoading] = useState<boolean>(false);
  const handleClosePlaceDetails = () => props.handleClosePlaceDetails();
  async function handleInPlaceButtonClick(rideId: number | undefined, placeId: number | undefined) {
    if (placeId === undefined || rideId === undefined) {
      throw TypeError('Selected Place or Ride is null');
    }
    try {
      setIsInPlaceButtonLoading(true);
      await postActions(props.territorySlug, selectedVehicle?.id || 0, [
        {
          name: ACTION_NAME_IN_PLACE,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
          },
        },
      ]);
      toast.success(`${t('roadmap.in-place')}`);
    } catch (e) {
      toast.error(`${t('roadmap.in-place-error')}`);
    } finally {
      setIsInPlaceButtonLoading(false);
    }
  }

  async function handleReadyToLeaveButtonClick(rideId: number | undefined, placeId: number | undefined) {
    if (placeId === undefined || rideId === undefined) {
      throw TypeError('Selected Place or Ride is null');
    }
    try {
      setIsReadyToLeaveButtonLoading(true);
      await postActions(props.territorySlug, selectedVehicle?.id || 0, [
        {
          name: ACTION_NAME_READY_TO_LEAVE,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
          },
        },
      ]);
      toast.success(`${t('roadmap.ready-to-leave')}`);
      handleClosePlaceDetails();
    } catch (e) {
      toast.error(`${t('roadmap.ready-to-leave-error')}`);
    } finally {
      setIsReadyToLeaveButtonLoading(false);
    }
  }

  async function handlePickUpButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsPickUpButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.id || 0, [
        {
          name: ACTION_NAME_PICK_UP,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      toast.success(`${t('roadmap.pickup')}`);
    } catch (e) {
      toast.error(`${t('roadmap.pickup-error')}`);
    } finally {
      setIsPickUpButtonLoading(false);
    }
  }

  async function handleAbsentButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsPickUpButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.id || 0, [
        {
          name: ACTION_NAME_ABSENT,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      toast.success(`${t('roadmap.absent')}`);
    } catch (e) {
      toast.error(`${t('roadmap.absent-error')}`);
    } finally {
      setIsPickUpButtonLoading(false);
    }
  }
  async function handleDropOffButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsDropOffButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.id || 0, [
        {
          name: ACTION_NAME_DROP_OFF,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      toast.success(`${t('roadmap.dropoff')}`);
    } catch (e) {
      toast.error(`${t('roadmap.dropoff-error')}`);
    } finally {
      setIsDropOffButtonLoading(false);
    }
  }
  return (
    <Modal show={props.showPlaceDetails} onHide={handleClosePlaceDetails} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className={'card-subtitle'}>
            {props.selectedPlace?.name} - #{props.selectedPlace?.order}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              <span className={'text-bold'}>Place : </span>
              <span className={'text-basic'}>{props.selectedPlace?.id}</span>
            </p>
            <p>
              <span className={'text-bold'}>Type : </span>
              <span className={'text-basic'}>{props.selectedPlace?.type}</span>
            </p>
            <p>
              <span className={'text-bold'}>Status : </span>
              <span className={'text-basic'}>{props.selectedPlace?.status}</span>
            </p>
          </Col>
          <Col>
            <p>
              <span className={'text-bold'}>Departure time : </span>
              <span className={'text-basic'}>
                {props.selectedPlace ? FormatTime(props.selectedPlace?.departure_time, false) : '-'}
              </span>
            </p>
            <p>
              <span className={'text-bold'}>Arrival time : </span>
              <span className={'text-basic'}>
                {props.selectedPlace ? FormatTime(props.selectedPlace?.arrival_time, false) : '-'}
              </span>
            </p>
            <p>
              <span className={'text-bold'}>Min time : </span>
              <span className={'text-basic'}>
                {props.selectedPlace ? FormatTime(props.selectedPlace?.min_time, false) : '-'}
              </span>
            </p>
            <p>
              <span className={'text-bold'}>Max time : </span>
              <span className={'text-basic'}>
                {props.selectedPlace ? FormatTime(props.selectedPlace?.max_time, false) : '-'}
              </span>
            </p>
          </Col>
        </Row>
        {props.territoryObj?.permissions.can_perform_roadmap_actions && props.selectedPlace !== null ? (
          <Row>
            {props.selectedPlace?.bookings.length ? (
              <>
                <hr className={'basic-hr mt-4 mb-4'} />
                <Col sm={6}>
                  <p className={'text-center mb-3'}>
                    <span className={'pickup-badge text-center'}>
                      <img
                        src={`/theme/media/arrow-up.svg`}
                        height="15"
                        className={'me-2'}
                        alt="Pickup or dropoff logo"
                      />
                      Pick-up
                      {isPickUpButtonLoading ? (
                        <Spinner className={'ms-2'} size={'sm'} animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <></>
                      )}
                    </span>
                  </p>
                  {getBookingsToPickUp(props.selectedPlace)?.map((booking: Booking, i: number) => (
                    <div key={i} className={'d-flex align-items-center justify-content-end me-5'}>
                      <p
                        className={`me-3 ${!enablePickUpButton(props.selectedPlace, props.currentPlace, booking) ? 'text-disabled' : 'text-basic'}`}>
                        {`${booking.customer_first_name} ${booking.customer_last_name}`}
                      </p>
                      <Form.Check
                        type={'checkbox'}
                        className={'action-checkbox me-3'}
                        checked={isPickUpButtonChecked(booking)}
                        disabled={!enablePickUpButton(props.selectedPlace, props.currentPlace, booking)}
                        onClick={() =>
                          handlePickUpButtonClick(
                            props.shift?.ride || 0,
                            props.selectedPlace?.id || 0,
                            booking.id,
                          )
                        }
                      />
                      {enableAbsentButton(props.selectedPlace, props.currentPlace, booking) ? (
                        <a
                          onClick={() =>
                            handleAbsentButtonClick(
                              props.shift?.ride || 0,
                              props.selectedPlace?.id || 0,
                              booking.id,
                            )
                          }>
                          <img
                            src={`/theme/media/absent.svg`}
                            height="25"
                            className={'mt-1'}
                            alt="Absent logo"
                          />
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </Col>
                <Col sm={6}>
                  <p className={'text-center mb-3'}>
                    <span className={'dropoff-badge text-center'}>
                      <img
                        src={`/theme/media/arrow-down.svg`}
                        height="15"
                        className={'me-2'}
                        alt="Pickup or dropoff logo"
                      />
                      Drop-Off
                      {isDropOffButtonLoading ? (
                        <Spinner className={'ms-2'} size={'sm'} animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        <></>
                      )}
                    </span>
                  </p>
                  {getBookingsToDropOff(props.selectedPlace)?.map((booking: Booking, i: number) => (
                    <div key={i} className={'d-flex align-items-center justify-content-start ms-5'}>
                      <Form.Check // prettier-ignore
                        type={'checkbox'}
                        className={'action-checkbox'}
                        checked={isDropOffButtonChecked(booking)}
                        disabled={!enableDropOffButton(props.selectedPlace, props.currentPlace, booking)}
                        onClick={() =>
                          handleDropOffButtonClick(
                            props.shift?.ride || 0,
                            props.selectedPlace?.id || 0,
                            booking.id,
                          )
                        }
                      />
                      <p
                        className={`ms-2 ${!enableDropOffButton(props.selectedPlace, props.currentPlace, booking) ? 'text-disabled' : 'text-basic'}`}>
                        {`${booking.customer_first_name} ${booking.customer_last_name}`}
                      </p>
                    </div>
                  ))}
                </Col>
              </>
            ) : (
              <></>
            )}
            <div className={'mt-3'}>
              <Col>
                <Row className={'mt-5'}>
                  {props.shift?.ride &&
                  props.selectedPlace?.id &&
                  props.currentPlace !== undefined &&
                  props.nextPlace !== undefined ? (
                    <>
                      <Col sm={6} className={'text-sm-end'}>
                        <Button
                          disabled={
                            !displayInPlaceButton(
                              props.currentPlace,
                              props.nextPlace,
                              props.selectedPlace,
                            )
                          }
                          onClick={() =>
                            handleInPlaceButtonClick(props.shift?.ride, props.selectedPlace?.id)
                          }
                          className={'submit-button'}>
                          {isInPlaceButtonLoading ? (
                            <span className={'d-flex justify-content-center'}>
                              <Spinner size={'sm'} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                              <span className={'ms-2'}>{t('roadmap.in-place-button')}</span>
                            </span>
                          ) : (
                            <span>{t('roadmap.in-place-button')}</span>
                          )}
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          disabled={!displayReadyToLeaveButton(props.currentPlace, props.selectedPlace)}
                          onClick={() =>
                            handleReadyToLeaveButtonClick(props.shift?.ride, props.selectedPlace?.id)
                          }
                          className={'submit-button'}>
                          {isReadyToLeaveButtonLoading ? (
                            <span className={'d-flex justify-content-center'}>
                              <Spinner size={'sm'} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                              <span className={'ms-2'}>{t('roadmap.ready-to-leave-button')}</span>
                            </span>
                          ) : (
                            <span>{t('roadmap.ready-to-leave-button')}</span>
                          )}
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
            </div>
          </Row>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
}
